import Home from './pages/Home';
import SignIn from './pages/SignIn';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from './firebase';


const App = () => {
  const [user] = useAuthState(auth);
  return (
    
      <div>
        {user ? <Home /> : <SignIn />}
      </div>

  );
}


export default App;