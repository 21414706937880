import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCFdfT99Gua5-U2HPLvE0Sc5z2QKQGKzl4",
    authDomain: "ai.lukecutting.com",
    projectId: "personal-ai-bot",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const firestore = getFirestore(app)

export { firebaseConfig, app, auth, firestore }