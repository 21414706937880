import React from 'react'
import { auth } from '../firebase'
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import'../css/SignIn.css'

const SignIn = () => {

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
  }

  return (
    <div className='signin-container'>
    <div className='signin-card'>
      <div className='signin-header'>
        <h1>Welcome to Luke's AI</h1>
      </div>
      <div className='signin-body'>
        <p>Please Login with your Google account.</p>
        <button className="signin__btn" onClick={signInWithGoogle}>Sign in with Google</button>
      </div>
    </div>
  </div>
  )
}

export default SignIn