import OpenAISVGLogo from '../OpenAISVGLogo'
import PersonLogo from '../PersonLogo'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { tomorrowNightBright } from 'react-syntax-highlighter/dist/esm/styles/hljs'

// Primary Chat Window
const ChatBox = ({chatLog, setChatInput, handleSubmit, chatInput, scrollWindow}) =>
  <section className="chatbox">
      <div className="chat-log">
        {chatLog.map((message, index) => (
          <ChatMessage key={index} message={message} />
        ))}
      </div>
        <div className="chat-input-holder">
      <form className="form" onSubmit={handleSubmit}>
          <input 
          rows="1"
          value={chatInput}
          onChange={(e)=> setChatInput(e.target.value)}
          className="chat-input-textarea" ></input>
          <button className="submit" type="submit">Submit</button>
          </form>
        </div>
      </section>

// Individual Chat Message
const ChatMessage = ({ message }) => {
  return (
    <div className={`chat-message ${message.user === "gpt" && "chatgpt"}`}>
    <div className="chat-message-center">
      <div className={`avatar ${message.user === "gpt" && "chatgpt"}`}>
        {message.user === "gpt" ? <OpenAISVGLogo /> : <PersonLogo />}
      </div>
      <div className="message">
      <ReactMarkdown className="message__markdown"
              children={message.message}
              remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
              components={{
                code({ node, inline, className, children, ...props }) {
                  const match = /language-(\w+)/.exec(className || 'language-js')
                  return !inline && match ? (
                    <SyntaxHighlighter
                      children={String(children).replace(/\n$/, '')}
                      style={tomorrowNightBright} language={match[1]} PreTag="div" {...props} showInlineLineNumbers={true}
                    />
                  ) : (<code className={className} {...props}>{children} </code>)
                }
        }} />
      </div>
    </div>
  </div>
  )
}

export default ChatBox